<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <img class="logo" src="../assets/icons/logo-coopharma.png" />
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link active"
              to="/farmacies"
              style="color: #0e9390 !important"
            >
              <i class="fas fa-home icon3"></i>Farmacias
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/calendar">
              <i class="icon3 fas fa-align-left"></i>Ciudades
            </router-link>
          </li> -->
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/ads">
              <i class="icon3 fas fa-align-left"></i>Anuncios
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" to="/ordersAdmin">
              <i class="fas fa-align-left icon3"></i>Órdenes
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/ads">
              <i class="fas fa-map-marker-alt icon3"></i>Anuncios
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/drivers">
              <i class="fas fa-users icon3"></i>Conductores
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/deliverySchedule">
              <i class="fas fa-align-left icon3"></i>Schedule Fee
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" to="/reportsAdmin">
              <i class="fas fa-list icon3"></i>Reportes
            </router-link>
          </li>
          <li class="nav-item">
            <select @change="changeLanguage($event)">
              <option
                v-for="(language, key) in languages"
                :key="key"
                :value="language.locale"
                >{{ language.title }}</option
              >
            </select>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/" @click="logout">
              <i class="fas fa-sign-out-alt icon3"></i>Logout
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
import Parse from "parse";
import { i18n } from "@/plugins/i18n";
export default {
  name: "NavBarAdmin",
  data() {
    return {
      languages: [
        { locale: "es", title: "Español" },
        { locale: "en", title: "Inglés" }
      ]
    };
  },
  methods: {
    logout() {
      Parse.User.logOut();
    },
    changeLanguage(locale) {
      i18n.locale = locale.target.value;
    }
  }
};
</script>
<style scoped>
.navbar,
.navbar-expand-lg,
.navbar-light,
.bg-light {
  background: #e0e0e0 !important;
}

.nav-item {
  font-family: "Montserrat", sans-serif;
}

.nav-link {
  color: rgba(0, 0, 0) !important;
  font-weight: 200;
  font-size: 13px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.5s;
}

.nav-link:hover {
  color: #0e9390 !important;
  margin-left: 20px;
  margin-right: 20px;
}

.logo-link {
  position: absolute;
  left: 20px;
  top: 10px;
}

.logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}

.navbar-toggler {
  border: 2px solid rgb(184, 184, 184) !important;
}
.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}

.icon3 {
  color: #029693;
  margin-right: 10px;
}

.nav-link2 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
  border-color: rgb(44, 44, 44);
  background-color: rgb(44, 44, 44);
}
.required {
  font-size: 15px;
  padding: 5px;
  background-color: #ffcccc;
}

.nav-link3 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
  border-color: #0e9390;
  background-color: #0e9390;
}

.nav-position {
  padding-top: 30px;
}

.second-nav {
  background: #0e9390;
  height: 50px;
  padding-top: 13px;
}

div.scroll {
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
  height: 500px;
}

.third-nav {
  background: rgb(44, 44, 44);
  height: 50px;
  padding-top: 13px;
}

.second-nav:hover {
  background: #0a6b69;
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.third-nav:hover {
  background: rgba(44, 44, 44, 0.692);
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}
</style>
