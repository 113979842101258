<template>
  <div>
    <NavBarAdmin></NavBarAdmin>
    <div
      class="container"
      style="margin-top: 100px; background: white; border: 1px solid rgb(192, 192, 192);"
    >
      <div class="row">
        <div class="col col-3 mt-3">
          <h2 class="mt-6">Anuncios</h2>
        </div>
        <div class="col col-5 mt-3">
          <input
            type="text"
            v-model="bannersearch"
            class="form-control"
            placeholder="Buscar Banner"
          />
        </div>
        <div class="col col-4">
          <button
            type="button"
            class="btn btn-primary create-btn"
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            CREAR NUEVO ANUNCIO
          </button>
        </div>
      </div>
      <table class="table table-hover table-striped">
        <thead>
          <tr class="row">
            <th class="header col col-6">IMAGEN</th>
            <th class="header col col-3">DESCRIPCÍON</th>
            <th class="header col col-3">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(adds, index) of allAds" :key="index" class="row">
            <td class="content col col-6">
              <img :src="adds.get('img').url()" class="image-ad" />
            </td>
            <td class="content col col-3">{{ adds.get("description") }}</td>
            <td class="content col col-3">
              <button type="button" @click="deleteAd(adds)" class="see-btn">
                Borrar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- modal upload picture -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="font-weight: 400; font-size: 15px;"
            >
              CREAR ANUNCIO
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <p style="font-size: 13px; font-weight: 600;">Descripcion</p>
              <input
                v-model="description"
                type="text"
                style="border-radius: 0; font-size: 13px;"
                class="form-control"
                id="exampleInputEmail1"
                placeholder="Descripcion"
              />
            </div>
            <div class="input-group mb-3" style="margin-top: 20px;">
              <div class="custom-file">
                <input
                  @change="onFileSelected"
                  type="file"
                  class="custom-file-input"
                  id="inputGroupFile02"
                  accept=".png, .jpg, .jpeg"
                />
                <label
                  class="custom-file-label"
                  for="inputGroupFile02"
                  style="font-family: 'Montserrat', sans-serif; font-size: 13px;"
                  >Imagen de anuncio</label
                >
                <!-- <p v-if="loading == true" style="text-align: center;">Subiendo imagen.....</p> -->
              </div>
            </div>
            <div>
              <input
                type="button"
                class="btn btn-dark btn-block"
                v-if="picture"
                value="Imagen Guardada"
                style="margin-top:-20px;"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="saveAd()"
              type="button"
              class="btn-primary"
              style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #0e9390; border: 0;"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Parse from "parse";
import NavBarAdmin from "../../components/NavBarAdmin.vue";
Vue.use(VueSweetalert2, options);
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};
export default {
  name: "Ads",
  components: {
    NavBarAdmin
  },
  data() {
    return {
      allAds: null,
      picture: null,
      url: null,
      locationAD: null,
      loading: null,
      superAdmin: false,
      admin: false,
      description: "",
      bannersearch: "",
      base64picture: ""
    };
  },
  mounted() {
    this.getAllAds();
    this.loading = false;
    this.superAdmin = Parse.User.current().get("superAdmin");
    this.admin = Parse.User.current().get("isAdmin");
    if (this.admin && !this.superAdmin) {
      this.$router.push("/userTabs");
    }

    if (!this.admin && !this.superAdmin) {
      this.$router.push("/");
    }
  },
  watch: {
    bannersearch() {
      if (this.bannersearch !== "") {
        this.getadsbydescription();
      } else {
        this.getAllAds();
      }
    }
  },
  methods: {
    async getAllAds() {
      const query = new Parse.Query("Banners");
      query.equalTo("state", true);
      query.find().then(results => {
        this.allAds = results;
      });
    },
    getImage(image) {
      return image.url();
    },
    locationAd(adLocation) {
      if (adLocation === "AS") {
        return "Despues del Splash";
      } else if (adLocation === "BN") {
        return "Banner";
      }
    },
    deleteAd(add) {
      add.set("state", false);
      add.save().then(() => {
        this.$swal({
          title: "¡Alerta!",
          text: "Anuncio borrado.",
          type: "info"
        });
        this.getAllAds();
      });
    },
    onFileSelected() {
      let self = this;
      const toBase64 = file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      async function Main() {
        const file = document.querySelector(".custom-file-input").files[0];
        self.picture = await toBase64(file);
        const base64Image = self.picture;
        const name = "photo.jpeg";
        const parseFile = new Parse.File(name, {
          base64: base64Image
        });
        // convierte la foto a base64
        parseFile.save().then(savedFile => {
          self.picture = savedFile;
        });
      }
      Main();
    },
    getadsbydescription() {
      let query = new Parse.Query("Banners");
      query.include("description");
      query.include("state");
      query.equalTo("state", true);
      query.contains("description", this.bannersearch);
      query.find().then(result => {
        this.allAds = result;
      });
    },
    saveAd() {
      if (this.description !== "" && this.picture !== null) {
        const ad = Parse.Object.extend("Banners");
        const query = new ad();
        query.set("state", true);
        query.set("description", this.description);
        query.set("img", this.picture);

        query.save().then(() => {
          this.$swal({
            title: "¡Alerta!",
            text: "Anuncio Guardado.",
            type: "success"
          });
          this.getAllAds();
          this.description = "";
          this.picture = null;
        });
      } else {
        this.$swal({
          title: "¡Alerta!",
          text: "Todos los campos son requeridos.",
          type: "info"
        });
      }
    }
  }
};
</script>
<style scoped>
.navbar,
.navbar-expand-lg,
.navbar-light,
.bg-light {
  background: #e0e0e0 !important;
}

.nav-item {
  font-family: "Montserrat", sans-serif;
}

.nav-link {
  color: black !important;
  font-weight: 200;
  font-size: 13px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.5s;
}

.nav-link:hover {
  color: #0e9390 !important;
  margin-left: 20px;
  margin-right: 20px;
}

.logo-link {
  position: absolute;
  left: 20px;
  top: 10px;
}

.logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}

.navbar-toggler {
  border: 2px solid rgb(184, 184, 184) !important;
}

.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}
.form-label {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}

.icon3 {
  color: #029693;
  margin-right: 10px;
}

.dropdown-toggle::after {
  display: none;
}

.nav-link2 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
}

.nav-position {
  padding-top: 30px;
}

.second-nav {
  background: #0e9390;
  height: 50px;
  padding-top: 13px;
}

.third-nav {
  background: rgb(44, 44, 44);
  height: 50px;
  padding-top: 13px;
}

.second-nav:hover {
  background: rgba(253, 83, 64, 0.692);
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.third-nav:hover {
  background: rgba(44, 44, 44, 0.692);
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}

.content {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
}

.delete-btn {
  background: rgba(212, 42, 42, 0.836);
  height: 35px;
  border: none;
  color: white;
  font-weight: 400;
  width: 100px;
}

.title-category {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 300;
}
.see-btn {
  border: 2px solid rgb(233, 6, 6);
  height: 35px;
  background: transparent;
  color: rgb(233, 6, 6);
  font-weight: 400;
  width: 100px;
}

.image-ad {
  height: 80px;
  width: 60%;
  object-fit: contain;
}

.create-btn {
  float: right;
  background: black;
  color: white;
  border-radius: 0;
  border: 0;
  font-size: 13px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.custom-file-input {
  border-top: 0 !important;
}
</style>
